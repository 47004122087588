import './InstantPaymentForm.css'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
    validateAfm,
    validateEmail,
    validateName,
} from '../../helpers/validators'

import PopupMessage from '../PopupMessage/PopupMessage'
import Select from 'react-select'
import FormField from '../FormField/FormField'
import Modal from '../Modal/Modal'
import squareSelected from '../../assets/images/squareSelected.svg'
import squareUnselected from '../../assets/images/squareUnselected.svg'
import { InstantPaymentTypes } from '../../enums/PaymentTypes'
import { validateRecaptcha } from '../../api/instant-payments'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const InstantPaymentForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [termsSelected, setTermsSelected] = useState(false)
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const [inputs, setInputs] = useState({
        name: '',
        afm: '',
        phoneNumber: '',
        email: '',
        address: '',
        residence: '',
        postalCode: '',
        area: '',
        paymentType: {
            value: '',
            label: 'Επιλέξτε τύπο...',
        },
        paymentCode: '',
        amount: '',
        comments: '',
    })

    const recaptchaMutation = useMutation({
        mutationFn: token =>
            validateRecaptcha({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                }),
            }),
    })

    const validateForm = () => {
        if (
            !inputs.name ||
            !inputs.address ||
            !inputs.paymentType.value ||
            !inputs.afm ||
            !inputs.residence ||
            !inputs.paymentCode ||
            !inputs.phoneNumber ||
            !inputs.postalCode ||
            !inputs.amount ||
            !inputs.email ||
            !inputs.area
        ) {
            return {
                isValid: false,
                message: 'Παρακαλώ συμπληρώστε όλα τα πεδία της φόρμας.',
            }
        }

        const isEmailValid = validateEmail(inputs.email)

        if (!isEmailValid) {
            return {
                isValid: false,
                message: 'Παρακαλώ εισάγετε μία σωστή διεύθυνση email.',
            }
        }

        const isNameValid = validateName(inputs.name)

        if (!isNameValid) {
            return {
                isValid: false,
                message: 'Παρακαλώ εισάγετε τo όνομά σας σε σωστή μορφή',
            }
        }

        if (inputs.comments.length > 255) {
            return {
                isValid: false,
                message:
                    'Τα σχόλια δεν μπορούν να ξεπερνάνε τους 255 χαρακτήρες',
            }
        }

        const isAfmValid = validateAfm(inputs.afm)

        if (!isAfmValid) {
            return {
                isValid: false,
                message: 'Παρακαλώ εισάγετε έναν έγκυρο Α.Φ.Μ.',
            }
        }

        return {
            isValid: true,
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const validationResult = validateForm()

        if (!validationResult.isValid) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: validationResult.message,
                type: 'error',
            }))

            return
        }

        const token = await executeRecaptcha('instantPaymentToken')

        recaptchaMutation.mutateAsync(token).then(data => {
            if (!data.success) {
                setPopup(values => ({
                    ...values,
                    shown: true,
                    message: 'Υπήρξε κάποιο σφάλμα. Παρακαλώ προσπαθήστε ξανά.',
                    type: 'error',
                }))

                return
            }

            setPopup(values => ({
                ...values,
                shown: true,
                message:
                    'Η υπηρεσία δυστυχώς δεν είναι διαθέσιμη. Παρακαλώ δοκιμάστε αργότερα.',
                type: 'error',
            }))

            return
        })
    }

    const handleChange = event => {
        if (event.label) {
            setInputs(values => ({ ...values, paymentType: event }))
            return
        }

        const { name, value } = event.target

        setInputs(values => ({ ...values, [name]: value }))
    }

    const Terms = () => {
        return (
            <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
                <button
                    className="w-5 "
                    onClick={() => setTermsSelected(!termsSelected)}
                >
                    <img
                        className="mx-auto"
                        src={termsSelected ? squareSelected : squareUnselected}
                        alt="select-button"
                    />
                </button>

                <p className="self-center text-center font-semibold md:text-left">
                    Συμφωνώ με τους &nbsp;
                    <a
                        className="text-blue"
                        href={
                            'https://hello.crowdapps.net/index/privacy-policy/'
                        }
                        rel="noreferrer"
                        target="_blank"
                    >
                        Όρους Χρήσης
                    </a>
                </p>
            </div>
        )
    }

    const paymentTypeOptions = [
        {
            value: InstantPaymentTypes.hospitalityTax,
            label: 'Τέλη Παρεπιδημούντων',
        },
        {
            value: InstantPaymentTypes.communalAreasTax,
            label: 'Τέλη χρήσης κοινόχρηστων χώρων',
        },
        {
            value: InstantPaymentTypes.waterBill,
            label: 'Λογαριασμός Ύδρευσης',
        },
        {
            value: InstantPaymentTypes.propertyContract,
            label: 'Μισθώματα ακινήτων, βοσκοτόπων, λατομείων και δασικών εκτάσεων',
        },
        {
            value: InstantPaymentTypes.tombAndReconditioningPermit,
            label: 'Δικαίωμα ανακομιδής και χρήσης οστεοφυλακίου',
        },
        {
            value: InstantPaymentTypes.gravePurchase,
            label: 'Αγορά τάφου',
        },
    ]

    return (
        <div className="background flex justify-center">
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            <Modal classes="my-20 mx-5 max-w-[65rem]">
                <div>
                    <header className="font-light text-3xl text-center border-b border-primary py-6 self-stretch">
                        Φόρμα GovPay Instant
                    </header>

                    <div className="flex flex-col px-14 py-10">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-6">
                            <FormField
                                type="text"
                                label="Ονοματεπώνυμο"
                                name="name"
                                labelOnSide={false}
                                value={inputs.name}
                                onChange={handleChange}
                            />

                            <FormField
                                type="number"
                                label="Α.Φ.Μ."
                                name="afm"
                                labelOnSide={false}
                                value={inputs.afm}
                                onChange={handleChange}
                            />

                            <FormField
                                type="text"
                                label="Τηλέφωνο"
                                name="phoneNumber"
                                labelOnSide={false}
                                value={inputs.phoneNumber}
                                onChange={handleChange}
                            />

                            <FormField
                                type="email"
                                label="Διεύθυνση Email"
                                name="email"
                                labelOnSide={false}
                                value={inputs.email}
                                onChange={handleChange}
                            />

                            <FormField
                                type="text"
                                label="Διεύθυνση"
                                name="address"
                                labelOnSide={false}
                                value={inputs.address}
                                onChange={handleChange}
                            />

                            <FormField
                                type="text"
                                label="Πόλη/Οικισμός/Χωριό"
                                name="residence"
                                labelOnSide={false}
                                value={inputs.residence}
                                onChange={handleChange}
                            />

                            <FormField
                                type="number"
                                label="Ταχ. Κωδ."
                                name="postalCode"
                                labelOnSide={false}
                                value={inputs.postalCode}
                                onChange={handleChange}
                            />

                            <FormField
                                type="text"
                                label="Περιοχή/Νομός"
                                name="area"
                                labelOnSide={false}
                                value={inputs.area}
                                onChange={handleChange}
                            />

                            <label className="text-base text-left text-primary mb-12">
                                Τύπος Πληρωμής
                                <Select
                                    isSearchable={false}
                                    defaultValue={{
                                        key: 0,
                                        value: '',
                                        label: 'Επιλέξτε τύπο...',
                                    }}
                                    value={inputs.paymentType}
                                    onChange={handleChange}
                                    className="pt-4 w-full"
                                    name="paymentType"
                                    options={paymentTypeOptions}
                                />
                            </label>

                            <FormField
                                type="text"
                                label="Κωδικός Οφειλής"
                                name="paymentCode"
                                labelOnSide={false}
                                value={inputs.paymentCode}
                                onChange={handleChange}
                            />

                            <FormField
                                type="number"
                                label="Ποσό Πληρωμής"
                                name="amount"
                                labelOnSide={false}
                                value={inputs.amount}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="text-base text-primary mb-5 flex flex-col gap-6 w-full font-bold lg:mb-14">
                            <div className="w-full">
                                Σχόλια - παρατηρήσεις (225 χαρακτήρες):
                            </div>

                            <div className="text-secondary">
                                <textarea
                                    className="border w-full h-28"
                                    onChange={handleChange}
                                    value={inputs.comments}
                                    name="comments"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col w-full gap-3 md:flex-row">
                            <Terms />

                            <button
                                className={`${
                                    !termsSelected && 'opacity-5  '
                                } bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 mx-auto md:ml-auto md:mr-0`}
                                type="submit"
                                disabled={
                                    !termsSelected ||
                                    recaptchaMutation.isLoading
                                }
                                onClick={handleSubmit}
                            >
                                Υποβολή
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default InstantPaymentForm
