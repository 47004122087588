import { useEffect, useContext } from 'react'

import HistoryItem from '../../components/mobile-components/HistoryItem/HistoryItem'
import HistoryTable from '../../components/HistoryTable/HistoryTable'
import Loading from '../../components/Loading/Loading'
import useMediaQuery from '../../hooks/useMediaQuery'
import { AuthContext } from '../../contexts/AuthContext'
import { useQuery } from '@tanstack/react-query'
import { getUsers } from '../../api/users'
import Roles from '../../enums/Roles'

const Users = () => {
    useEffect(() => {
        document.title = 'Χρήστες - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')
    const { token } = useContext(AuthContext)

    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const { data: users, isLoading } = useQuery({
        queryKey: ['users'],
        queryFn: () => getUsers(options),
    })

    const headings = ['Ον/μο - Επωνυμία', 'Email', 'Ρόλος', 'Εγγραφή']

    const getRoleLabel = role => {
        switch (role) {
            case Roles.admin:
                return 'Διαχειριστής'
            case Roles.citizen:
                return 'Πολίτης'
            case Roles.company:
                return 'Επιχείρηση'
            default:
                return '---'
        }
    }

    const rows = users?.map(user => {
        return {
            0: `${user?.profile?.firstName ?? ''} ${
                user?.profile?.lastName ?? '---'
            }`,
            1: user?.email ?? '-',
            2: getRoleLabel(user?.role),
            3: user?.profile?.taxid ? 'TaxisNet' : 'Φόρμα εγγραφής',
        }
    })

    return (
        <div className="flex flex-col items-center m-10">
            <h2 className="text-primary text-3xl text-center font-light mt-6 md:mt-24 mb-6">
                Χρήστες
            </h2>

            {isLoading ? (
                <div className="pt-24">
                    <Loading />
                </div>
            ) : isMobile ? (
                <>
                    {users?.length > 0 ? (
                        users?.map((request, index) => {
                            return (
                                <HistoryItem
                                    key={request.id}
                                    headings={headings}
                                    rows={rows[index]}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center py-20">
                            Δεν βρέθηκαν χρήστες
                        </h2>
                    )}
                </>
            ) : (
                <div className="my-10 w-full flex justify-center">
                    <HistoryTable
                        headings={headings}
                        rows={rows ?? []}
                        noDataText="Δεν βρέθηκαν χρήστες"
                    />
                </div>
            )}
        </div>
    )
}

export default Users
