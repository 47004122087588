import './Header.css'
import logo from '../../assets/images/logo.png'
import govPayLogo from '../../assets/images/govpay-logo.svg'

import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import HeaderButtons from '../HeaderButtons/HeaderButtons'
import MobileHeader from '../mobile-components/MobileHeader/MobileHeader'

import useAuth from '../../hooks/useAuth'
import useMediaQuery from '../../hooks/useMediaQuery'

const Header = ({
    includeLogo = false,
    withShadow = false,
    withBorder = false,
    includeButtons = false,
    includeProfile = true,
    includeLogout = true,
    isModalHeader = false,
}) => {
    const { isAdmin } = useAuth()
    const isMobile = useMediaQuery('(max-width:1024px)')

    return isMobile ? (
        <>
            <MobileHeader isModalHeader={isModalHeader} />
        </>
    ) : (
        <header
            className={`
                py-5
                px-12 
                bg-white
                ${withShadow ? 'with-shadow' : ''} 
                ${withBorder ? 'with-border' : ''}`}
        >
            <p className="container mx-auto text-lg">Πιλοτική Λειτουργία</p>

            <div className="container mx-auto flex flex-col justify-between items-center lg:flex-row">
                <div className="flex items-center gap-4">
                    {includeLogo && (
                        <Link to={isAdmin() ? '/admin' : '/'}>
                            <img width="80" height="80" src={logo} alt="logo" />
                        </Link>
                    )}

                    <div>
                        <div className="flex items-center gap-3">
                            <h1 className="text-2xl font-medium text-[#222222]">
                                Δήμος Βύρωνα
                            </h1>
                            <img
                                width="85"
                                height="85"
                                src={govPayLogo}
                                alt="govpaylogo"
                            />
                        </div>

                        <h2 className="text-xl font-light text-[#222222]">
                            Πλατφόρμα Ηλεκτρονικών Πληρωμών
                        </h2>
                    </div>
                </div>

                {includeButtons && (
                    <HeaderButtons
                        includeLogout={includeLogout}
                        includeProfile={includeProfile}
                    />
                )}
            </div>
        </header>
    )
}

Header.propTypes = {
    includeLogo: PropTypes.bool,
    withShadow: PropTypes.bool,
    withBorder: PropTypes.bool,
    includeButtons: PropTypes.bool,
    includeLogout: PropTypes.bool,
    includeProfile: PropTypes.bool,
    isModalHeader: PropTypes.bool,
}

export default Header
