import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
// import Loading from '../components/Loading/Loading'
import HistoryTable from '../components/HistoryTable/HistoryTable'
import BoxSelectButton from '../components/TableButtons/BoxSelectButton'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import HistoryItem from '../components/mobile-components/HistoryItem/HistoryItem'

import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { formatAmount, formatDate } from '../helpers/formatters'
import useMediaQuery from '../hooks/useMediaQuery'
import useAuth from '../hooks/useAuth'
import { fetchAPI } from '../helpers/api'
// import { getInstallmentDebts } from '../api/debts'
// import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'

const DebtsInstallments = () => {
    useEffect(() => {
        document.title =
            'Οφειλές σε Ρύθμιση - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')
    const { token } = useContext(AuthContext)
    const [debtsToPay, setDebtsToPay] = useState({
        totalAmount: 0.0,
        debtsId: [],
    })
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { getCurrentUserID, refreshToken } = useAuth()
    // const options = {
    //     headers: {
    //         Authorization: `Bearer ${token.access_token}`,
    //     },
    // }

    // const { isLoading, data } = useQuery({
    //     queryKey: ['installmentDebts', options],
    //     queryFn: () => getInstallmentDebts(options),
    // })

    const data = {
        debts: [],
    }

    // if (isLoading) {
    //     return <Loading />
    // }

    const initiateCheckout = async () => {
        try {
            // Refresh the tokens before initiating the NBG checkout
            refreshToken()

            const res = await fetchAPI(
                `${process.env.REACT_APP_API_URL}/payments/initiateCheckout`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userId: getCurrentUserID(),
                        payment: {
                            orderId: debtsToPay.debtsId.toString(),
                            orderAmount: debtsToPay.totalAmount,
                        },
                    }),
                }
            )

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η υπηρεσία δεν είναι διαθέσιμη</p>
                    </>
                ),
                type: 'error',
            }))

            console.log(res)

            return res
        } catch (err) {
            console.error(err)
            return false
        }
    }

    const submitHandler = async e => {
        e.preventDefault()

        if (debtsToPay.debtsId.length === 0) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>
                            Παρακαλώ επιλέξτε τουλάχιστον μία οφειλή προς
                            πληρωμή.
                        </p>
                    </>
                ),
                type: 'error',
            }))

            return
        }

        const nbgData = await initiateCheckout()

        if (!nbgData.success) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        {nbgData.message ? (
                            <p> {nbgData.message} </p>
                        ) : (
                            <>
                                <p>Κάτι πήγε στραβά! </p>
                                <p>Παρακαλώ προσπαθήστε ξανά.</p>
                            </>
                        )}
                    </>
                ),
                type: 'error',
            }))

            return
        }
    }

    const headings = [
        '',
        'Κωδικός Οφειλής',
        'Διακανονισμός',
        'Μοναδικός Κωδικός Διακανονισμού',
        'Αριθμός Δόσης Οφειλής',
        'Παρατηρήσεις',
        'Ημερομηνία Βεβαίωσης',
        'Ημερομηνία Λήξης Πληρωμής',
        'Βεβαιωθέν Ποσό',
        'Προσαυξήσεις',
        'Σύνολο',
    ]

    const rows = data.debts.map(debt => {
        return {
            0: (
                <BoxSelectButton
                    key={debt.id}
                    debtId={debt.id}
                    debtTotalAmount={debt.totalAmount}
                    debtsToPay={debtsToPay}
                    setDebtsToPay={setDebtsToPay}
                />
            ),
            1: debt.id ?? '-',
            2: debt.moneylistReason ?? '-',
            3: debt.mainInstallmentId ?? '-',
            4: debt.installmentNo ?? '-',
            5: debt.comments ?? '-',
            6: formatDate(debt.confirmationDate) ?? '-',
            7: formatDate(debt.dueDate) ?? '-',
            8: formatAmount(debt.confirmedAmount) ?? '-',
            9: formatAmount(debt.addedAmount) ?? '-',
            10: formatAmount(debt.totalAmount) ?? '-',
        }
    })

    return (
        <div className="flex flex-col">
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            {isMobile ? (
                <>
                    <h2 className="text-primary text-2xl text-center my-8">
                        Βεβαιωμένες Οφειλές
                    </h2>

                    {data?.debts.length > 0 ? (
                        data.debts.map((debt, index) => {
                            return (
                                <HistoryItem
                                    key={debt.id}
                                    headings={headings}
                                    rows={rows[index]}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center">
                            Δεν βρέθηκαν βεβαιωμένες οφειλές
                        </h2>
                    )}
                </>
            ) : (
                <Modal classes="w-full max-w-7xl flex flex-col">
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />

                    <h2 className="text-primary text-3xl font-light mt-12 mb-6 self-center">
                        Οφειλές σε Ρύθμιση
                    </h2>

                    <div className="overflow-auto">
                        <div className="w-[80%] lg:w-full flex flex-col justify-start items-center grow">
                            <HistoryTable
                                headings={headings}
                                rows={rows}
                                noDataText={
                                    <>
                                        <div>
                                            Παρακαλούμε χρησιμοποιήστε τη
                                            διαδικασία&nbsp;
                                            <Link
                                                className="font-bold text-blue"
                                                to={'/balance-requests'}
                                            >
                                                αίτησης υπολοίπου&nbsp;
                                            </Link>
                                            για να ενημερωθείτε για τις οφειλές
                                            σας.
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </Modal>
            )}

            {data?.debts.length > 0 && (
                <button
                    className="self-end m-10 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10"
                    type="submit"
                    onClick={submitHandler}
                >
                    Πληρωμή
                    {debtsToPay.totalAmount !== 0
                        ? debtsToPay.totalAmount + ' €'
                        : null}
                </button>
            )}
        </div>
    )
}

export default DebtsInstallments
