import Modal from '../components/Modal/Modal'
import ModalToggle from '../components/ModalToggle/ModalToggle'
import Loading from '../components/Loading/Loading'
import Header from '../components/Header/Header'
import StatusText from '../components/StatusText/StatusText'
import PopupMessage from '../components/PopupMessage/PopupMessage'

import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import useMediaQuery from '../hooks/useMediaQuery'

import { formatDate } from '../helpers/formatters'
import { useQuery } from '@tanstack/react-query'
import { getBalanceRequest } from '../api/balance-requests'
import useAuth from '../hooks/useAuth'
import { fetchAPI } from '../helpers/api'
import { getProfile } from '../api/users'
import { getPaymentsOfUser } from '../api/payments'

const BalanceRequestMore = () => {
    const { token } = useContext(AuthContext)
    const { getCurrentUserID, refreshToken } = useAuth()
    const isMobile = useMediaQuery('(max-width:1024px)')
    const [debtsText, setDebtsText] = useState(
        'Σε αναμονή απάντησης απο το δήμο'
    )
    const [paymentsToCheck, setPayments] = useState([])
    const { id: balanceRequestId } = useParams()
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const { isLoading, data: balanceRequestData } = useQuery({
        queryKey: ['balanceRequest', options],
        queryFn: () => getBalanceRequest(balanceRequestId, options),
    })

    const { isLoadingPayments, data: paymentsData } = useQuery({
        queryKey: ['paymentsHistory', getCurrentUserID(), options],
        queryFn: () => getPaymentsOfUser(getCurrentUserID(), options),
    })

    const govpayDebts = balanceRequestData?.data?.govpayDepts

    useEffect(() => {
        const hasDebts = balanceRequestData?.data?.hasDebts
        // We use true or false value because we have null value before update
        if (hasDebts === true) {
            setDebtsText('ΝΑΙ')
        } else if (hasDebts === false) {
            setDebtsText('ΟΧΙ')
        }
    }, [balanceRequestData])

    useEffect(() => {
        document.title = 'Λεπτομέρειες - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'
    }, [])

    useEffect(() => {
        if (paymentsData) {
            const payments = paymentsData.payments
            setPayments(payments)
        }
    }, [paymentsData])

    if (isLoading || isLoadingPayments) {
        return <Loading />
    }

    const initiateCheckout = async debt => {
        const profileData = await getProfile({
            headers: {
                Authorization: `Bearer ${token.access_token}`,
            },
        })
        const profile = profileData?.profile
        let fullName
        profile
            ? (fullName = `${profile?.firstName} ${profile?.lastName}`)
            : (fullName = '-')
        try {
            // Refresh the tokens before initiating the NBG checkout
            refreshToken()

            const checkoutBody = {
                payment: {
                    description: debt.description,
                    paymentCode: debt.paymentCode,
                    amount: debt.amount,
                    associateTitle: fullName,
                    debts: [
                        {
                            debtId: debt.id,
                            totalAmount: parseFloat(debt.amount),
                        },
                    ],
                },
                userId: getCurrentUserID(),
            }

            const res = await fetchAPI(
                `${process.env.REACT_APP_API_URL}/payments/initiateCheckout`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(checkoutBody),
                }
            )

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η υπηρεσία δεν είναι διαθέσιμη</p>
                    </>
                ),
                type: 'error',
            }))

            return res
        } catch (err) {
            console.error(err)
            return false
        }
    }

    const submitHandler = async debt => {
        const nbgData = await initiateCheckout(debt)
        if (!nbgData.success) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        {nbgData.message ? (
                            <p> {nbgData.message} </p>
                        ) : (
                            <>
                                <p>Κάτι πήγε στραβά! </p>
                                <p>Παρακαλώ προσπαθήστε ξανά.</p>
                            </>
                        )}
                    </>
                ),
                type: 'error',
            }))
            return
        }
    }

    return (
        <>
            {balanceRequestData.data &&
                (isMobile ? (
                    <>
                        <div className="flex flex-col">
                            {popup.shown && (
                                <PopupMessage
                                    message={popup.message}
                                    type={popup.type}
                                    closePopUp={() =>
                                        setPopup(values => ({
                                            ...values,
                                            shown: false,
                                        }))
                                    }
                                    showCloseButton={true}
                                />
                            )}
                            <div className="flex flex-col pb-32">
                                <header className="text-3xl text-center py-8">
                                    Λεπτομέρειες Αίτησης Υπολοίπου
                                </header>
                                <Modal classes="w-[80%] pl-8 pr-12 pb-8 mb-10 pt-12 self-center md:w-1/2 md:mb-0">
                                    <div className="flex flex-col self-center justify-around">
                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Τίτλος:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {balanceRequestData.data.title}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Ημερομηνία Υποβολής Αίτησης:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {formatDate(
                                                    balanceRequestData.data
                                                        .createdAt
                                                )}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Λεπτομέρειες:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {!balanceRequestData.data
                                                    .details ? (
                                                    <p>
                                                        Δεν υπάρχουν ακόμα
                                                        σχόλια για την
                                                        συγκεκριμένη αίτηση
                                                    </p>
                                                ) : (
                                                    balanceRequestData.data
                                                        .details
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Κατάσταση:
                                            </div>

                                            <StatusText
                                                kind="balance-request"
                                                status={
                                                    balanceRequestData.data
                                                        .status
                                                }
                                            />
                                        </div>
                                    </div>
                                </Modal>
                                {govpayDebts.length > 0 && debtsText === 'ΝΑΙ' && (
                                    <>
                                        {govpayDebts.map((debt, index) => (
                                            <ModalToggle
                                                key={index}
                                                type="info"
                                                data={debt}
                                                pay={() => submitHandler(debt)}
                                                paymentsToCheck={
                                                    paymentsToCheck
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Modal classes="w-full max-w-7xl flex flex-col">
                            <Header
                                includeLogo={false}
                                withBorder={true}
                                includeButtons={true}
                            />

                            <div className="flex flex-col">
                                {popup.shown && (
                                    <PopupMessage
                                        message={popup.message}
                                        type={popup.type}
                                        closePopUp={() =>
                                            setPopup(values => ({
                                                ...values,
                                                shown: false,
                                            }))
                                        }
                                        showCloseButton={true}
                                    />
                                )}
                                <div className="flex flex-col pb-5 my-10">
                                    <Modal classes="w-[75%] 2xl:w-1/2 pb-8 self-center md:pt-14 lg:pt-2">
                                        <header className="font-light text-3xl text-center border-b border-primary py-6 self-stretch mb-10">
                                            Λεπτομέρειες αίτησης υπολοίπου
                                        </header>
                                        <div className="flex flex-col self-center justify-around p-10 lg:p-0">
                                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                                    Κωδικός Αίτησης:
                                                </div>
                                                <div className="w-full text-secondary lg:w-[50%] border-b">
                                                    {
                                                        balanceRequestData.data
                                                            .title
                                                    }
                                                </div>
                                            </div>

                                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                                    Ημερομηνία Υποβολής Αίτησης:
                                                </div>
                                                <div className="w-full text-secondary lg:w-[50%] border-b">
                                                    {formatDate(
                                                        balanceRequestData.data
                                                            .createdAt
                                                    )}
                                                </div>
                                            </div>
                                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                                    Κατάσταση:
                                                </div>

                                                <StatusText
                                                    kind="balance-request"
                                                    status={
                                                        balanceRequestData.data
                                                            .status
                                                    }
                                                />
                                            </div>
                                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                                    Υπόλοιπο προς τον δήμο:
                                                </div>
                                                <div className="w-full text-secondary lg:w-[50%] border-b">
                                                    <p>{debtsText}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                {govpayDebts.length > 0 && debtsText === 'ΝΑΙ' && (
                                    <>
                                        {govpayDebts.map((debt, index) => (
                                            <ModalToggle
                                                key={index}
                                                type="info"
                                                data={debt}
                                                pay={() => submitHandler(debt)}
                                                paymentsToCheck={
                                                    paymentsToCheck
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </Modal>
                    </>
                ))}
        </>
    )
}

export default BalanceRequestMore
