import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Modal from '../components/Modal/Modal'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import FormField from '../components/FormField/FormField'

import { useMutation } from '@tanstack/react-query'
import { validateEmail } from '../helpers/validators'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { sendUpdateAdminPasswordRequest } from '../api/users'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const ResetAdminPassword = () => {
    const { isLoggedIn } = useAuth()

    const [email, setEmail] = useState('')
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const isMobile = useMediaQuery('(max-width:1024px)')
    const navigate = useNavigate()

    useEffect(() => {
        document.title =
            'Επαναφορά κωδικού πρόσβασης - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'

        if (isLoggedIn()) {
            navigate('/')
        }
    }, [])

    const mutation = useMutation({
        mutationFn: email =>
            sendUpdateAdminPasswordRequest({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            }),
        onSuccess: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Εάν υπάρχει λογαριασμός που ταιριάζει με τα στοιχεία που
                        υποβάλατε, θα σταλεί ένας σύνδεσμος επαναφοράς κωδικού
                        πρόσβασης στο email του λογαριασμού σας. Παρακαλούμε
                        ελέγξτε τα εισερχόμενά σας.
                    </p>
                ),
                type: 'success',
            }))
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Κάτι πήγε στραβά! </p>
                        <p>Παρακαλούμε προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    const handleSubmit = e => {
        e.preventDefault()

        const isEmailValid = validateEmail(email)

        if (!isEmailValid) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Λάθος μορφή email! </p>
                        <p>Παρακαλούμε εισάγετε ένα έγκυρο email.</p>
                    </>
                ),
                type: 'error',
            }))

            return
        }

        mutation.mutate(email)
    }

    const divClasses = isMobile
        ? 'py-8 flex flex-col items-center'
        : 'py-16 px-28 text-center'

    const loginBody = (
        <div className="flex flex-col items-center md:m-20 m-10 md:w-auto w-full">
            <p className="p-6 md:my-10 md:self-center max-w-[40ch]">
                Για να επαναφέρετε τον κωδικό πρόσβασής σας, παρακαλούμε
                συμπληρώστε την διεύθυνση email σας παρακάτω.
            </p>

            <form
                className="flex flex-col w-full px-12 md:px-0"
                onSubmit={handleSubmit}
            >
                <FormField
                    required
                    type="email"
                    label="Email"
                    name="email"
                    labelOnSide={false}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />

                <div className="flex md:gap-8 justify-between md:py-12">
                    <button
                        disabled={mutation.isLoading}
                        type="submit"
                        className="disabled:cursor-not-allowed disabled:bg-opacity-50 m-auto bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                    >
                        Επαναφορά κωδικού πρόσβασης
                    </button>
                </div>
            </form>
        </div>
    )

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            <main className="flex justify-center items-center m-3">
                <Modal classes=" w-full h-fit m-5 md:max-w-5xl md:m-12">
                    <Header includeLogo={true} withBorder={true} />

                    <div className={divClasses}>
                        <h1 className="font-bold  text-primary text-3xl ">
                            Επαναφορά κωδικού
                        </h1>

                        {isMobile ? (
                            loginBody
                        ) : (
                            <Modal classes="max-w-10xl lg:m-24 min-w-max mx-8">
                                {loginBody}
                            </Modal>
                        )}
                    </div>
                </Modal>
            </main>

            <Footer />
        </>
    )
}

export default ResetAdminPassword
