import _fetch from '.'

export async function createInstantPayment(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/instant-payments/initiateCheckout`
    return _fetch(url, options)
}

export async function validateRecaptcha(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/instant-payments/validateRecaptcha`
    return _fetch(url, options)
}

export async function getAllInstantPayments(filters, options = {}) {
    const { afm: taxid, status, title } = filters

    const url = new URL(`${process.env.REACT_APP_API_URL}/instant-payments/`)

    url.searchParams.set('taxid', taxid)
    url.searchParams.set('status', status)
    url.searchParams.set('title', title)

    return _fetch(url, options)
}

export async function getInstantPayment(paymentId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/instant-payments/${paymentId}`
    return _fetch(url, options)
}

export async function updateInstantPayment(paymentId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/instant-payments/${paymentId}`
    return _fetch(url, options)
}
