import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Modal from '../components/Modal/Modal'
import FormField from '../components/FormField/FormField'
import { Link } from 'react-router-dom'

import userIcon from '../assets/images/user-icon.svg'
import taxisIcon from '../assets/images/taxis-icon.png'
import eye from '../assets/images/eye.svg'
import eyeSlash from '../assets/images/eye-slash.svg'

import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const Login = () => {
    const { isLoggedIn, login, taxisLogin } = useAuth()

    const [queryParams] = useSearchParams()
    const taxisToken = queryParams.get('t')
    const [errorMsgTaxis, seterrorMsgTaxis] = useState(
        queryParams.get('taxiserror')
    )
    const [inputs, setInputs] = useState({})

    const [passwordVisible, setPasswordVisible] = useState(false)
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    const isMobile = useMediaQuery('(max-width:1024px)')
    const navigate = useNavigate()
    // const taxisLink = `${process.env.REACT_APP_API_URL}/auth/taxis/redirect`;
    const taxisLink = '#'

    const handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        setInputs(values => ({ ...values, [name]: value }))
    }

    const [errorMsgAdmin, setErrorMsgAdmin] = useState(false)

    const loginWithCredentials = async e => {
        e.preventDefault()
        await login(inputs.email, inputs.password)

        if (!isLoggedIn()) {
            setErrorMsgAdmin(true)
        }
    }

    useEffect(() => {
        document.title = 'Σύνδεση - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'

        if (isLoggedIn()) {
            navigate('/')
        }

        if (taxisToken) {
            taxisLogin(taxisToken)
            if (!isLoggedIn()) {
                seterrorMsgTaxis(true)
            }
        }
    }, [])

    const loginBody = (
        <div className="flex flex-col items-center md:m-20 m-10 md:w-auto w-full">
            <div className="bg-accent rounded-full w-fit p-6 md:my-10 md:self-center">
                <img width="30" height="30" src={userIcon} alt="user" />
            </div>

            <form
                className="flex flex-col w-full px-12 md:px-0"
                onSubmit={loginWithCredentials}
            >
                <FormField
                    type="email"
                    label="Email"
                    name="email"
                    labelOnSide={false}
                    onChange={handleChange}
                />
                <label className="text-base text-left text-primary mb-12">
                    <p className="text-base font-bold">Κωδικός</p>
                    <div className="flex border-b border-gray-300 items-end">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            name="password"
                            className=" w-full mt-4"
                            onChange={handleChange}
                            min={0}
                            id="adminPassword"
                        />
                        <button
                            onClick={togglePasswordVisibility}
                            type="button"
                            className={'my-1'}
                        >
                            <img
                                src={passwordVisible ? eye : eyeSlash}
                                alt="show-pass"
                            />
                        </button>
                    </div>
                </label>

                {errorMsgAdmin ? (
                    <div className=" self-start text-left underline text-red-700 text-sm pb-7">
                        Το email ή ο κωδικός που εισάγατε είναι λανθασμένος
                    </div>
                ) : null}

                <div className="flex md:gap-8 justify-between md:py-12">
                    <button
                        type="submit"
                        className="m-auto bg-accent text-white border border-accent hover:bg-white hover:text-accent rounded-lg py-3.5 px-10 transition-all"
                    >
                        Σύνδεση
                    </button>
                    <Link
                        to="/register"
                        className="m-auto bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                    >
                        Εγγραφή
                    </Link>
                </div>
            </form>
        </div>
    )

    {
        isMobile ? (
            loginBody
        ) : (
            <Modal classes="max-w-10xl lg:m-24 min-w-max mx-8">
                {loginBody}
            </Modal>
        )
    }

    const divClasses = isMobile
        ? 'py-8 flex flex-col items-center'
        : 'py-16 px-28 text-center'

    const TaxisLogin = () => (
        <>
            <main className="flex justify-center items-center">
                <div className="py-16 px-10 text-center flex flex-col items-center">
                    <p className="font-light text-secondary text-l md:text-xl">
                        Αποκτήστε πρόσβαση σε προσωποποιημένες υπηρεσίες,
                        δημιουργήστε έναν λογαριασμό και παρακολουθήστε την
                        κατάσταση αιτημάτων καθώς και την θυρίδα σας. Για πλήρη
                        πρόσβαση στις e-Υπηρεσίες, συνδεθείτε μέσω TAXIS NET.
                    </p>

                    <div className="bg-accent rounded-full w-fit p-4 my-10">
                        <img width="30" height="30" src={userIcon} alt="user" />
                    </div>

                    <a
                        className="flex items-center gap-2 text-accent cursor-not-allowed font-semibold text-lg shadow-md rounded-xl px-4 py-2 transition-all duration-200 ease-in-out border border-white hover:border-primary"
                        href={taxisLink}
                    >
                        Σύνδεση με
                        <img src={taxisIcon} alt="TaxisNet" />
                    </a>

                    {errorMsgTaxis ? (
                        <div className=" underline font-semibold text-red-700 text-base pt-10">
                            Η διαδικασία ταυτοποίησης μέσω taxis-net απέτυχε.
                        </div>
                    ) : null}
                </div>
            </main>
        </>
    )

    return (
        <>
            <main className="flex justify-center items-center m-3">
                <Modal classes=" w-full h-fit m-5 md:max-w-5xl md:m-12">
                    <Header includeLogo={true} withBorder={true} />

                    <div className={divClasses}>
                        <h1 className="font-bold  text-primary text-3xl ">
                            Είσοδος
                        </h1>
                        <div className="w-full">
                            <TaxisLogin />
                        </div>
                        {isMobile ? (
                            loginBody
                        ) : (
                            <Modal classes="max-w-10xl lg:m-24 min-w-max mx-8">
                                {loginBody}
                            </Modal>
                        )}
                    </div>
                </Modal>
            </main>

            <Footer />
        </>
    )
}

export default Login
