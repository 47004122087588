import Header from '../components/Header/Header'
import Modal from '../components/Modal/Modal'
import Loading from '../components/Loading/Loading'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import ProfileEditableItem from '../components/ProfileItem/ProfileEditableItem'
import ProfileItem from '../components/ProfileItem/ProfileItem'

import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { getRoleText, getResidentText } from '../helpers/roles'
import { formatDate } from '../helpers/formatters'
import { validateEmail } from '../helpers/validators'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getProfile, updateProfile } from '../api/users'
import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'
import Roles from '../enums/Roles'
import NoticeItem from '../components/ProfileItem/ProfileNoticeItem'
import useProfileCompleted from '../hooks/useProfileCompleted'

const Profile = () => {
    useEffect(() => {
        document.title = 'Προφίλ - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'
    }, [])

    const { getUserRole, getEmailVerified, logout } = useAuth()
    const isMobile = useMediaQuery('(max-width:1024px)')
    const currentRole = getUserRole()
    const [formData, setFormData] = useState({
        email: null,
        address: null,
        telephone: null,
        mobileNumber: null,
        residenceType: null,
    })
    const [editing, setEditing] = useState(false)
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const queryClient = useQueryClient()
    const profileCompleted = useProfileCompleted(token)

    const { isLoading, data } = useQuery({
        queryKey: ['profile', options],
        queryFn: () => getProfile(options),
    })
    useEffect(() => {
        if (data) {
            setFormData({
                email: data.email || '',
                address: data.profile.address || '',
                telephone: data.profile.telephone || '',
                mobileNumber: data.profile.mobileNumber || '',
                residenceType: data.profile.residenceType || '',
            })
        }
    }, [data])
    const mutation = useMutation({
        mutationFn: updatedProfile =>
            updateProfile({
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProfile),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['profile'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: <p> Επιτυχής ενημέρωση του προφίλ </p>,
                type: 'success',
            }))

            if (formData.email !== data.email) {
                // Logout user to login again with new email only if changes
                setTimeout(logout, 5000)
            }
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Κάτι πήγε στραβά! </p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const handleFieldChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value,
        }))
    }

    const handleUserUpdate = async () => {
        if (!validateEmail(formData.email)) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Λάθος μορφή email! </p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))
            return
        }

        setEditing(false)

        const sendEmailVerificationOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token.access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.email,
            }),
        }

        // Check if the email has changed before sending verification
        if (data.email !== formData.email) {
            const emailVerification = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/send-mail-verification`,
                sendEmailVerificationOptions
            )

            const emailRes = await emailVerification.json()
            if (!emailRes) {
                setPopup(values => ({
                    ...values,
                    shown: true,
                    message: (
                        <>
                            <p>Κάτι πήγε στραβά! </p>
                            <p>Παρακαλώ προσπαθήστε ξανά.</p>
                        </>
                    ),
                    type: 'error',
                }))
                return
            }
        }

        // Proceed with the mutation regardless of whether email verification was sent
        mutation.mutate({
            email: formData.email,
            role: currentRole,
            taxid: data.profile.taxid,
            lastName: data.profile.lastName,
            fatherName: data.profile.fatherName,
            motherName: data.profile.motherName,
            birthYear: data.profile.birthYear || '1900',
            address: formData.address,
            telephone: formData.telephone,
            mobileNumber: formData.mobileNumber,
            residenceType: formData.residenceType,
        })
    }
    const emailVerifed = getEmailVerified()
    const profileContent = (
        <div className="flex flex-col justify-center items-center w-full md:w-auto max-w-xl md:max-w-none md:my-14 md:mx-8">
            {!emailVerifed ? (
                <NoticeItem
                    title={'Επιβεβαίωση του email'}
                    text={`Προκειμένου να έχετε πλήρη πρόσβαση στην πλατφόρμα,
                                παρακαλούμε όπως συμπληρώσετε την ηλεκτρονική σας
                                διεύθυνση παρακάτω και στην συνέχεια ακολουθήστε τον
                                σύνδεσμο που θα σας έχει σταλεί στο ηλεκτρονικό σας
                                ταχυδρομείο.`}
                />
            ) : null}

            {!profileCompleted ? (
                <NoticeItem
                    title={'Συμπλήρωση πεδίων'}
                    text={`Προκειμένου να έχετε πλήρη πρόσβαση στην πλατφόρμα,
                παρακαλούμε όπως συμπληρώσετε όλα τα επεξεργάσιμα πεδία του
                προφίλ παρακάτω`}
                />
            ) : null}

            <Modal classes="flex flex-col  gap-4 w-full max-w-4xl my-5">
                <div className="border-b w-full border-black">
                    <h2 className="text-2xl text-center my-4">
                        {currentRole === Roles.citizen
                            ? 'Στοιχεία Δημότη'
                            : 'Στοιχεία Επιχείρησης'}
                    </h2>
                </div>

                <div className="w-full flex flex-col items-center gap-3 my-8">
                    <ProfileItem label={'ΑΦΜ'} value={data.profile.taxid} />
                    <ProfileItem
                        label={
                            currentRole === Roles.citizen
                                ? 'Επώνυμο'
                                : 'Επωνυμία'
                        }
                        value={data.profile.lastName}
                    />
                    {currentRole === Roles.citizen && (
                        <>
                            <ProfileItem
                                label={'Όνομα'}
                                value={data.profile.firstName}
                            />
                            <ProfileItem
                                label={'Πατρώνυμο'}
                                value={data.profile.fatherName}
                            />
                            <ProfileItem
                                label={'Μητρώνυμο'}
                                value={data.profile.motherName}
                            />
                            <ProfileItem
                                label={'Έτος γέννησης'}
                                value={data.profile.birthYear}
                            />
                            <ProfileEditableItem
                                defaultValue={data.profile.address}
                                setEditing={setEditing}
                                setterFunction={value =>
                                    handleFieldChange('address', value)
                                }
                                name={'address'}
                                label={'Διεύθυνση'}
                                type={'text'}
                                editing={editing}
                            />
                            <ProfileEditableItem
                                defaultValue={data.profile.telephone}
                                setEditing={setEditing}
                                setterFunction={value =>
                                    handleFieldChange('telephone', value)
                                }
                                name={'telephone'}
                                label={'Κινητό Τηλέφωνο'}
                                type={'text'}
                                editing={editing}
                            />
                            <ProfileEditableItem
                                defaultValue={data.profile.mobileNumber}
                                setEditing={setEditing}
                                setterFunction={value =>
                                    handleFieldChange('mobileNumber', value)
                                }
                                name={'mobileNumber'}
                                label={'Τηλέφωνο'}
                                type={'text'}
                                editing={editing}
                            />
                            <ProfileEditableItem
                                defaultValue={getResidentText(
                                    data.profile.residenceType
                                )}
                                setEditing={setEditing}
                                setterFunction={value =>
                                    handleFieldChange('residenceType', value)
                                }
                                name={'residenceType'}
                                label={'Δημότης/Κάτοικος'}
                                type={'select'}
                                editing={editing}
                            />
                        </>
                    )}
                    <ProfileEditableItem
                        defaultValue={data.email}
                        setEditing={setEditing}
                        setterFunction={value =>
                            handleFieldChange('email', value)
                        }
                        name={'email'}
                        label={'Email'}
                        type={'email'}
                        editing={editing}
                    />
                    <ProfileItem
                        label={'Ρόλος συστήματος'}
                        value={getRoleText(data.role)}
                    />
                    <ProfileItem
                        label={'Ημερομηνία Εγγραφής'}
                        value={formatDate(data.createdAt)}
                    />
                </div>

                {editing && (
                    <div className="flex flex-col md:flex-row justify-end m-10">
                        <button
                            className="self-center m-3 w-48 md:order-2 bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                            onClick={handleUserUpdate}
                        >
                            Αποθήκευση
                        </button>
                        <button
                            className="md:mr-14 m-3 w-48 self-center bg-white text-primary border border-primary hover:bg-primary hover:text-white rounded-lg py-3.5 px-10 transition-all"
                            onClick={() => {
                                setEditing(!editing)
                            }}
                        >
                            Ακύρωση
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    )

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            {isMobile ? (
                profileContent
            ) : (
                <Modal classes="w-full max-w-7xl flex flex-col">
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />

                    {profileContent}
                </Modal>
            )}
        </>
    )
}

export default Profile
