import { useState } from 'react'
import PropTypes from 'prop-types'
import FormField from '../FormField/FormField'
import { PaymentFormTypes } from '../../enums/PaymentTypes'
import { getPaymentTypeText } from '../../helpers/payments'
import SelectField from '../SelectField/SelectField'
import PopupMessage from '../PopupMessage/PopupMessage'
import { IoInformationCircleSharp } from 'react-icons/io5'
import bill from '../../assets/images/logariasmos.png'

const FormBodyDesktop = props => {
    const [paymentType, setPaymentType] = useState('')
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const toggleHydrometerInfo = () => {
        setPopup(values => ({
            ...values,
            shown: true,
            message: (
                <>
                    <h1 className="font-bold">Υπόδειγμα Υδρόμετρου</h1>
                </>
            ),
            popupImage: (
                <>
                    <img src={bill} alt="bill"></img>
                </>
            ),
            type: 'image',
        }))
    }

    return (
        <div className="px-10 lg:px-16 py-8 flex-1 h-full flex flex-col justify-start">
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                    popupImage={popup.popupImage}
                />
            )}
            <form
                className="payment-form flex flex-col items-between"
                onSubmit={props.submitHandler}
            >
                <SelectField
                    name="paymentType"
                    label="Τύπος Πληρωμής:"
                    options={[
                        {
                            value: PaymentFormTypes.hospitalityTax,
                            label: getPaymentTypeText(
                                PaymentFormTypes.hospitalityTax
                            ),
                        },
                        {
                            value: PaymentFormTypes.communalAreasTax,
                            label: getPaymentTypeText(
                                PaymentFormTypes.communalAreasTax
                            ),
                        },
                        {
                            value: PaymentFormTypes.waterBill,
                            label: getPaymentTypeText(
                                PaymentFormTypes.waterBill
                            ),
                        },
                        {
                            value: PaymentFormTypes.propertyContract,
                            label: getPaymentTypeText(
                                PaymentFormTypes.propertyContract
                            ),
                        },
                        {
                            value: PaymentFormTypes.tombAndReconditioningPermit,
                            label: getPaymentTypeText(
                                PaymentFormTypes.tombAndReconditioningPermit
                            ),
                        },
                        {
                            value: PaymentFormTypes.gravePurchase,
                            label: getPaymentTypeText(
                                PaymentFormTypes.gravePurchase
                            ),
                        },
                    ]}
                    onChange={e => {
                        setPaymentType(e.target.value)
                        props.handleChange(e)
                    }}
                    value={paymentType}
                    required
                />
                <FormField
                    type="text"
                    label="Κωδικός Οφειλής:"
                    name="paymentCode"
                    onChange={props.handleChange}
                />

                <FormField
                    type="text"
                    label="Αιτιολογία:"
                    name="description"
                    onChange={props.handleChange}
                />

                <FormField
                    type="number"
                    label="Ποσό:"
                    name="amount"
                    includeEuro={true}
                    onChange={props.handleChange}
                />

                <FormField
                    type="text"
                    label="Διεύθυνση:"
                    name="address"
                    value={props.address}
                    onChange={props.handleChange}
                />

                {paymentType === PaymentFormTypes.waterBill && (
                    <>
                        <div className="relative justify-between">
                            <FormField
                                type="text"
                                label="Υδρόμετρο:"
                                name="hydrometer"
                                onChange={props.handleChange}
                                classes={'max-w-[90%]'}
                            />
                            <button
                                type="button"
                                className="flex-shrink-0 h-fit w-fit absolute right-0 top-[10px]"
                                onClick={toggleHydrometerInfo}
                            >
                                <IoInformationCircleSharp className="text-[#9b100c] text-2xl hover:text-primary transition" />
                            </button>
                        </div>
                    </>
                )}

                <button
                    className="bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 self-center"
                    type="submit"
                >
                    Πληρωμή
                </button>
            </form>
        </div>
    )
}

FormBodyDesktop.propTypes = {
    submitHandler: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    address: PropTypes.string,
}

export default FormBodyDesktop
