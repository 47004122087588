import HistoryTable from '../../components/HistoryTable/HistoryTable'
import StatusText from '../../components/StatusText/StatusText'
import Loading from '../../components/Loading/Loading'
import HistoryItem from '../../components/mobile-components/HistoryItem/HistoryItem'
import EditButton from '../../components/TableButtons/EditButton'
import TableFilters from '../../components/TableFilters/TableFilters'
import MobileTableFilters from '../../components/TableFilters/MobileTableFilters'

import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { formatDate, formatAmount } from '../../helpers/formatters'
import useMediaQuery from '../../hooks/useMediaQuery'
import { useQuery } from '@tanstack/react-query'
import { getAllPayments } from '../../api/payments'

const Payments = () => {
    useEffect(() => {
        document.title =
            'Διαχείριση πληρωμών - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')
    const { token } = useContext(AuthContext)
    const [filterValues, setFilterValues] = useState({
        afm: '',
        title: '',
        status: '',
    })
    const [showFilters, setShowFilters] = useState(false)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const { isLoading, data: payments } = useQuery({
        queryKey: ['adminPayments', options, filterValues],
        queryFn: () => getAllPayments(filterValues, options),
    })

    const handleFilterChange = newFilters => {
        setFilterValues(newFilters)
    }

    if (showFilters) {
        return (
            // TODO: Fix mobile filters functionality
            <MobileTableFilters
                kind="payment"
                toggleFilters={() => setShowFilters(!showFilters)}
                setURL={() => null}
            />
        )
    }

    const headings = [
        'ΑΦΜ Οφειλέτη',
        'Ονοματεπώνυμο Οφειλέτη',
        'Κωδικός συστήματος',
        'Κωδικός Οφειλής',
        'Αιτιολογία',
        'Ημερομηνία Υποβολής',
        'Ημερομηνία Ενημέρωσης',
        'Ποσό',
        'Κατάσταση',
        'Επεξεργασία',
    ]

    const rows = payments?.data?.map(payment => {
        return {
            0: payment.user.profile?.taxid ?? '-',
            1:
                payment.user.profile?.firstName &&
                payment.user?.profile.lastName
                    ? `${payment.user.profile.firstName} ${payment.user.profile.lastName}`
                    : '-',
            2: payment.systemCode ?? '-',
            3: payment.paymentCode ?? '-',
            4: payment.description ?? '-',
            5: formatDate(payment.paymentDate) ?? '-',
            6: formatDate(payment.updatedAt) ?? '-',
            7: formatAmount(payment.amount) ?? 0,
            8: <StatusText key={7} kind="payment" status={payment.status} />,
            9: <EditButton key={8} id={payment.id} kind="payment" />,
        }
    })

    return (
        <div className="flex flex-col items-center m-10">
            <h2 className="text-primary text-3xl font-light text-center mt-6 md:mt-24 mb-6">
                Πληρωμές οφειλών
            </h2>

            {isMobile ? /*
             * <button
             *     onClick={() => setShowFilters(!showFilters)}
             *     className="self-auto bg-blue text-white border border-blue hover:bg-white hover:text-blue rounded-lg py-3.5 px-10 transition-all"
             * >
             *     Φίλτρα
             * </button>
             */ null : (
                <TableFilters
                    kind="payment"
                    filterValues={filterValues}
                    handleFilterChange={handleFilterChange}
                />
            )}

            {isLoading ? (
                <div className="pt-24">
                    <Loading />
                </div>
            ) : isMobile ? (
                <>
                    {payments.data.length > 0 ? (
                        payments.data.map((payment, index) => {
                            return (
                                <HistoryItem
                                    key={payment.id}
                                    headings={headings}
                                    rows={rows[index]}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center py-20">
                            Δεν βρέθηκαν πληρωμές
                        </h2>
                    )}
                </>
            ) : (
                <div className="my-10 w-full">
                    <HistoryTable
                        headings={headings}
                        rows={rows}
                        noDataText="Δεν βρέθηκαν πληρωμές"
                    />
                </div>
            )}
        </div>
    )
}

export default Payments
