import logoutIcon from '../../assets/images/logout.svg'
import logoutHoveredIcon from '../../assets/images/logout-hovered.svg'
import userIcon from '../../assets/images/user-icon.svg'
import userHoveredIcon from '../../assets/images/user-hovered-icon.svg'

import PropTypes from 'prop-types'
import TokenCountdown from '../TokenCountdown/TokenCountdown'

import { Link, NavLink } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useMediaQuery from '../../hooks/useMediaQuery'

const HeaderButtons = ({ includeProfile = true, includeLogout = true }) => {
    const { isLoggedIn, logout } = useAuth()
    const isDesktop = useMediaQuery('(min-width:1024px)')

    return (
        <div className="flex items-center gap-4">
            {isLoggedIn() ? (
                <>
                    {isDesktop ? <TokenCountdown /> : null}

                    {includeProfile && (
                        <NavLink
                            to="/profile"
                            id="profileBtn"
                            className="bg-accent border border-accent hover:bg-white transition-all p-3 rounded-full"
                            onMouseOver={e => {
                                e.currentTarget.children[0].src =
                                    userHoveredIcon
                            }}
                            onMouseOut={e => {
                                e.currentTarget.children[0].src = userIcon
                            }}
                        >
                            <img
                                width="25"
                                height="25"
                                src={userIcon}
                                alt="user"
                            />
                        </NavLink>
                    )}

                    {includeLogout && (
                        <button
                            className="bg-primary border border-primary hover:bg-white hover:text-primary rounded-full p-3 flex justify-center items-center transition-all"
                            onClick={logout}
                            onMouseOver={e => {
                                e.currentTarget.children[0].src =
                                    logoutHoveredIcon
                            }}
                            onMouseOut={e => {
                                e.currentTarget.children[0].src = logoutIcon
                            }}
                        >
                            <img
                                width="25"
                                height="25"
                                src={logoutIcon}
                                alt="logout"
                            />
                        </button>
                    )}
                </>
            ) : (
                <>
                    <Link
                        className="bg-accent text-white border border-accent hover:bg-white hover:text-accent rounded-lg py-3.5 px-10 transition-all"
                        to="/register"
                    >
                        Εγγραφή
                    </Link>
                    <>
                        <Link
                            className="bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                            to="/login"
                        >
                            Σύνδεση
                        </Link>
                    </>
                </>
            )}
        </div>
    )
}

HeaderButtons.propTypes = {
    includeProfile: PropTypes.bool,
    includeLogout: PropTypes.bool,
}

export default HeaderButtons
