import Modal from '../components/Modal/Modal'
import Loading from '../components/Loading/Loading'
import Header from '../components/Header/Header'
import StatusText from '../components/StatusText/StatusText'

import { useParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useQuery } from '@tanstack/react-query'
import useMediaQuery from '../hooks/useMediaQuery'

import { formatAmount, formatDate } from '../helpers/formatters'
import { getPayment } from '../api/payments'

const PaymentMore = () => {
    const { token } = useContext(AuthContext)
    const isMobile = useMediaQuery('(max-width:1024px)')
    const { id: paymentId } = useParams()
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const { isLoading, data: paymentData } = useQuery({
        queryKey: ['payment', paymentId, options],
        queryFn: () => getPayment(paymentId, options),
    })

    useEffect(() => {
        document.title =
            'Λεπτομέρειες Πληρωμής - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'
    }, [])

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            {paymentData &&
                (isMobile ? (
                    <>
                        <div className="flex flex-col">
                            <div className="flex flex-col pb-32">
                                <header className="text-3xl text-center py-8">
                                    Λεπτομέρειες Πληρωμής
                                </header>
                                <Modal classes="w-[80%] pl-8 pr-12 pb-8 pt-20 self-center md:w-1/2">
                                    <div className="flex flex-col self-center justify-around">
                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Κωδικός Συστήματος:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {paymentData.data.systemCode}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Ημερομηνία Τελευταίας
                                                Επεξεργασίας:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {formatDate(
                                                    paymentData.data.updatedAt
                                                )}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Λεπτομέρειες:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {!paymentData.data.details ? (
                                                    <p>
                                                        Δεν υπάρχουν ακόμα
                                                        σχόλια για την
                                                        συγκεκριμένη πληρωμή
                                                    </p>
                                                ) : (
                                                    paymentData.data.description
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Ποσό Πληρωμής
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {formatAmount(
                                                    paymentData.data.amount
                                                )}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Κατάσταση:
                                            </div>

                                            <StatusText
                                                kind="payment"
                                                status={paymentData.data.status}
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </>
                ) : (
                    <Modal classes="w-full max-w-7xl flex flex-col">
                        <Header
                            includeLogo={false}
                            withBorder={true}
                            includeButtons={true}
                        />

                        <div className="flex flex-col">
                            <div className="flex flex-col pb-32">
                                <header className="text-3xl text-center py-8">
                                    Λεπτομέρειες Πληρωμής
                                </header>

                                <Modal classes="w-[80%] pl-8 pr-12 pb-8 pt-20 self-center md:w-1/2">
                                    <div className="flex flex-col self-center justify-around">
                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Κωδικός Συστήματος:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {paymentData.data.systemCode}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Ημερομηνία Τελευταίας
                                                Επεξεργασίας:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {formatDate(
                                                    paymentData.data.updatedAt
                                                )}
                                            </div>
                                        </div>

                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Λεπτομέρειες:
                                            </div>
                                            <div className="w-full text-secondary lg:w-2/3">
                                                {paymentData.data.details ===
                                                '' ? (
                                                    <p>
                                                        Δεν υπάρχουν ακόμα
                                                        σχόλια για την
                                                        συγκεκριμένη πληρωμή
                                                    </p>
                                                ) : (
                                                    paymentData.data.description
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Ποσό πληρωμής:
                                            </div>

                                            {formatAmount(
                                                paymentData.data.amount
                                            )}
                                        </div>
                                        <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                                            <div className="w-full text-left lg:text-right lg:w-1/3">
                                                Κατάσταση:
                                            </div>

                                            <StatusText
                                                kind="payment"
                                                status={paymentData.data.status}
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </Modal>
                ))}
        </>
    )
}

export default PaymentMore
